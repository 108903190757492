<template>
  <app-dropdown-menu v-if="me" :items="menuItems" position="right-end">
    <app-user-avatar
      :avatar-url="me.avatar_url"
      class="!h-10 !w-10"
      :name="me.full_name"
      :size="100"
    />
  </app-dropdown-menu>
</template>

<script lang="ts" setup>
import type { DropdownMenuItemDefinition } from "@pollen/ui-library";

const { me, logout } = useCurrentUser();

const menuItems: DropdownMenuItemDefinition[] = [
  {
    label: "Me déconnecter",
    icon: "ph:sign-out",
    listeners: {
      click: logout,
    },
  },
];
</script>
